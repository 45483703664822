// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// const {
//   initializeAppCheck,
//   ReCaptchaV3Provider,
// } = require("firebase/app-check");

// Your web app's Firebase configuration

const firebaseConfig = {
  // apiKey: "AIzaSyC7tyjJXbWmtDVw_HqJ1bEhrBXRrn7Vysc",

  // authDomain: "fruti-portal.firebaseapp.com",

  // projectId: "fruti-portal",

  // storageBucket: "fruti-portal.appspot.com",

  // messagingSenderId: "757580862986",

  apiKey: "AIzaSyBeygKxLf20cBS2J5-il8SWuzH1fjYlwOE",
  authDomain: "avian-destiny-253922.firebaseapp.com",
  projectId: "avian-destiny-253922",
  storageBucket: "avian-destiny-253922.appspot.com",
  messagingSenderId: "656048792899",
  appId: "1:656048792899:web:77c3b8c6d4ca525d83fbf6"
  // apiKey: "AIzaSyCbJY7_4ufLKnTod_rZKAXCBpwPgqA82xU",
  // authDomain: "sj-ipa-1b3e1.firebaseapp.com",
  // projectId: "sj-ipa-1b3e1",
  // storageBucket: "sj-ipa-1b3e1.appspot.com",
  // messagingSenderId: "901712927561",
  // appId: "1:901712927561:web:a028fb76283d7931f29898"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const db = getFirestore();

const storage = getStorage(app);

const auth = getAuth(app);



const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfzaREiAAAAABd7K5UHeTyrb3l0oQyx0hJ6Xlbc'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6Lc-p8scAAAAAEcaWvs-VRZVXacV_VZ4zwdcDRdX"),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });

export { app, db, storage, auth };
