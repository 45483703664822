import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import BooksContext from "../context/BooksContext";

import { db, storage } from "../firebase";
import {
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";

import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteField,
  orderBy,
  query,
  doc,
  deleteDoc,
  Timestamp,
  writeBatch,
  where,
} from "firebase/firestore";

import { useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { useDropzone } from "react-dropzone";

import departamentos from "./data_schema/departamentos.json";
import municipios from "./data_schema/municipios.json";

import FilesUploadComponent from "../components/files-upload-component";

import PillGroup from "../components/PillGroup";
import options_IncidentType from "../incident-types";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

// const options_IncidentType = [
//   { value: "Dishonesty",
//     label: "Dishonesty" },
//   {
//     value: "Great Bodily Injury",
//     label: "Great Bodily Injury",
//   },
//   {
//     value: "Officer Involved Shooting",
//     label: "Officer Involved Shooting",
//   },
//   { value: "Sexual Assault",
//     label: "Sexual Assault" },
// ];

let defaultFiletype = "IPA Report";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "30px",
    height: "30px",
    boxShadow: state.isFocused ? null : null,
    border: "0.04rem solid",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "30px",
  }),
};

function App() {
  const { books, setBooks } = useContext(BooksContext);

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [CASE_NUMBER, setCASE_NUMBER] = useState("");
  const [INCIDENT_DATE, setINCIDENT_DATE] = useState("");
  // const [ASSOCIATED_OFFICERS, setASSOCIATED_OFFICERS] = useState("");
  const [ASSOCIATED_OFFICERS_LIST, setASSOCIATED_OFFICERS_LIST] = useState([]);

  const [INCIDENT_TYPE, setINCIDENT_TYPE] = useState("");
  const [INCIDENT_TYPES, setINCIDENT_TYPES] = useState([]);

  const [date, setDate] = useState();
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const submitForm = async (data) => {
    // console.log("sent");
    let firebase_id = "";
    try {
      const docRef = await addDoc(collection(db, "cases"), data);
      console.log("Document written with ID: ", docRef.id);
      // console.log(docRef);
      firebase_id = docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    return firebase_id;
  };

  const batchChange = async () => {
    const batch = writeBatch(db);

    const q = query(
      collection(db, "cases"),
      where(
        "INCIDENT_TYPE",
        "==",
        "Great Bodily Injury and Officer Involved Shooting"
      )
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      updateDoc(doc.ref, {
        // INCIDENT_TYPES: ["Great Bodily Injury", "Officer Involved Shooting"],
        // INCIDENT_TYPE: deleteField(),
      });
    });
  };

  const sendDocData = async () => {
    let incidentTypes_subarray = [];

    INCIDENT_TYPES.forEach(function (value) {
      incidentTypes_subarray.push(value["value"]);
    });
    const formData = {
      // CASE_NUMBER: parseInt(CASE_NUMBER),
      CASE_NUMBER: CASE_NUMBER,
      // ASSOCIATED_OFFICERS: ASSOCIATED_OFFICERS,
      INCIDENT_DATE: INCIDENT_DATE ? INCIDENT_DATE : new Date(),
      // INCIDENT_TYPE: INCIDENT_TYPE["value"] ? INCIDENT_TYPE["value"] : "",

      INCIDENT_TYPES: incidentTypes_subarray ? incidentTypes_subarray : [],
      // INCIDENT_TYPES:

      ASSOCIATED_OFFICERS_LIST: ASSOCIATED_OFFICERS_LIST
        ? ASSOCIATED_OFFICERS_LIST
        : [],
      // MUNICIPIO: MUNICIPIO ? MUNICIPIO["name"] : null,
      RECORD_CREATION_DATE: Timestamp.now(),
    };

    const firebase_id = submitForm(formData);
    formData["id"] = await firebase_id;

    setData((prevState) => [formData, ...prevState]);
    console.log(formData);
    // alert(JSON.stringify(formData))

    setCASE_NUMBER("");
    // setASSOCIATED_OFFICERS("");
    setASSOCIATED_OFFICERS_LIST([]);
    setINCIDENT_DATE("");
    // setINCIDENT_TYPE("");
    setINCIDENT_TYPES([]);
    setDate();
    setBooks((prevState) => [formData, ...prevState]);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    let warning;
    warning = myFiles.length < 1 ? "No Files have been added below" : "";

    if (CASE_NUMBER !== "") {
      if (window.confirm(`Submit case information? ${warning}`)) {
        if (myFiles.length < 1) {
          sendDocData(); // No files added
        } else {
          uploadFile(); // Files added
        }
        //some code
      } else {
        //some code
      }
    } else {
      alert("Please enter Case Number at minimum");
    }
  };

  const getCollection = async () => {
    let scoped_data = [];

    const q = query(
      collection(db, "cases"),
      // where("CEDULA", "!=", null),
      orderBy("RECORD_CREATION_DATE", "asc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      let obj = {
        CASE_NUMBER: doc.data().CASE_NUMBER,
        ASSOCIATED_OFFICERS_LIST: doc.data().ASSOCIATED_OFFICERS_LIST,
        INCIDENT_DATE: doc.data().INCIDENT_DATE.toDate(),
        // INCIDENT_TYPE: doc.data().INCIDENT_TYPE,
        INCIDENT_TYPES: doc.data().INCIDENT_TYPES,
        RECORD_CREATION_DATE: doc.data().RECORD_CREATION_DATE,
        DATE_MODIFIED: doc.data().DATE_MODIFIED,
        id: doc.id,
      };
      // console.log(obj.id);
      setData((prevState) => [obj, ...prevState]);
      scoped_data.unshift(obj);
    });
    // console.log(data);
    setBooks(scoped_data);
  };

  useEffect(() => {
    getCollection();
  }, []);

  const removeColor = (id) => {
    // remove the color of the id that is passed by using the filter function
    const newColors = data.filter((color) => color.id !== id);
    // update your state with filtered colors
    setData(newColors);
  };

  const deleteFolder = (caseNumber) => {
    const listRef = ref(storage, `${caseNumber}/`);
    // console.log('listRef - caseNumber/', listRef)

    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          // console.log('itemRef', itemRef)
          // deleteFile(itemRef.fullPath, itemRef.name)
          deleteObject(itemRef)
            .then(() => {
              // File deleted successfully
            })
            .catch((error) => {
              console.log(error);
              // Uh-oh, an error occurred!
            });
        });
        res.prefixes.forEach((folderRef) => {
          // console.log('folderRef', folderRef)
          deleteFolder(folderRef.fullPath);
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });
  };

  const deleteHandler = async (id, caseNumber) => {
    deleteFolder(caseNumber);
    // console.log("sent");
    // console.log("should delete ", id);
    try {
      await deleteDoc(doc(db, "cases", id));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
    // console.log(data);
    removeColor(id);
    // console.log(data);
  };

  const handleDateChange = (d) => {
    // console.log(d);
    setINCIDENT_DATE(d);
    setDate(d);
  };

  const [myFiles, setMyFiles] = useState([]);

  const uploadFile = async () => {
    await Promise.all(
      myFiles.map((file, i, myFilesObj) => {
        console.log(file);

        const storageRef = ref(
          storage,
          `cases/${CASE_NUMBER}/${fileMetadata[i].type}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);

        setUploadStatus("Uploading");

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progressSnap =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            setCurrentlyUploadingObj(snapshot.task._blob.data_);

            // console.log("Upload is " + progressSnap + "% done");
            setProgress(progressSnap);

            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            if (i + 1 === myFilesObj.length) {
              // Last one.
              // setMyFiles([]);
              setUploadStatus("--All Files Uploaded--");
              sendDocData();
              // setProgress(0)
              // console.log("last one finished !!!");
            } else {
              // Not last one.
            }
          }
        );
      })
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);

      setSelectedImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      // setSelectedImages(
      //   myFiles.map((file) =>
      //     Object.assign(file, {
      //       preview: URL.createObjectURL(file),
      //     })
      //   )
      // );

      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, inputRef } =
    useDropzone({ onDrop });

  const removeFile = (file, index) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);

    console.log("removeFile...");
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    console.log(acceptedFiles);
    // console.log(myFiles);

    let fileMetadataCopy = [...fileMetadata];
    fileMetadataCopy.splice(index, 1);
    // console.log(fileMetadataCopy)

    setFileMetadata(fileMetadataCopy);
  };

  const removeAll = () => {
    console.log("removeAll...");
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    inputRef.current.value = "";
    console.log(acceptedFiles);

    setMyFiles([]);
  };

  function detectFiletype(filename) {
    var fileExt = filename.split(".").pop();
    switch (fileExt) {
      case "pdf":
        return "IPA Report";
      case "mp3":
      case "wav":
        return "Audio";
      case "jpg":
      case "jpeg":
      case "png":
        return "Photo";
      default:
        return filename === undefined ? defaultFiletype : filename;
    }
  }

  const [currentlyUploadingObj, setCurrentlyUploadingObj] = useState({});
  const [uploadStatus, setUploadStatus] = useState(null);
  const [progress, setProgress] = useState(0);

  const [fileMetadata, setFileMetadata] = useState();

  useEffect(() => {
    // Here we want to sync fileMetadata with myFiles

    let fileMetadataTemp = [];
    // console.log(myFiles)
    if (myFiles.length !== fileMetadata?.length) {
      myFiles.map((file, i) => {
        let fileType = fileMetadata[i]?.type;
        // let fileType = 'init'
        let fileDataObj = {
          path: file.path,
          size: file.size,
          // type: defaultFiletype,
          // type: fileType === undefined ? defaultFiletype : fileType,
          type: detectFiletype(file.path),
        };
        fileMetadataTemp.push(fileDataObj);
      });
      setFileMetadata(fileMetadataTemp);
    }
  }, [myFiles, fileMetadata]);

  const [selectedImages, setSelectedImages] = useState();

  const selected_images = selectedImages?.map((file) => (
    <div>
      <img
        src={file.preview}
        style={{
          maxHeight: "200px",
        }}
        alt=""
      />
    </div>
  ));

  // useEffect(() => {
  //   // if (ASSOCIATED_OFFICERS_LIST.length > 0) alert(ASSOCIATED_OFFICERS_LIST[0])
  //   // console.log(ASSOCIATED_OFFICERS_LIST)
  //   alert('changed')
  // }, [ASSOCIATED_OFFICERS_LIST])

  return (
    <div style={{ width: "100% !important" }}>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h2
          style={{
            fontFamily: "Newake",
            // fontSize: "calc(26px + 1vmin)",
          }}
        >
          SJ IPA - Admin Portal
        </h2>
      </div>

      <div
        className="float-container"
        style={{
          paddingTop: "15px",
        }}
      >
        <div className="float-child create">
          <div style={{ padding: "10px" }}>
            <div id="first-label" className="label">
              Case Number:
            </div>
            <input
              placeholder="IYear-Sequence"
              value={CASE_NUMBER}
              onChange={(event) => setCASE_NUMBER(event.target.value)}
            />
            <div className="label">Incident Date:</div>

            <DatePicker
              // placeholderText="DD/MM/YYYY"
              placeholderText="MM/DD/YYYY"
              // dateFormat="dd/MM/yyyy"
              dateFormat="MM/dd/yyyy"
              selected={date}
              onChange={(date) => handleDateChange(date)}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {"<"}
                  </button>
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {">"}
                  </button>
                </div>
              )}
            />
            <div
              onClick={() => {
                alert(JSON.stringify(INCIDENT_TYPES));
              }}
              className="label"
            >
              Incident Type:
            </div>
            <Select
              // value={INCIDENT_TYPE}
              value={INCIDENT_TYPES}
              options={options_IncidentType}
              // onChange={setINCIDENT_TYPE}
              onChange={setINCIDENT_TYPES}
              // isSearchable={true}
              isMulti={true}
              // styles={customStyles}
              // defaultValue={options[0]}
              isClearable={true}
              // getOptionLabel={(option) => option["name"]}
            />
            {/* {JSON.stringify(INCIDENT_TYPES)} */}
            <div className="label">Associated Officers:</div>
            <PillGroup
              ASSOCIATED_OFFICERS_LIST={ASSOCIATED_OFFICERS_LIST}
              setASSOCIATED_OFFICERS_LIST={setASSOCIATED_OFFICERS_LIST}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              // marginTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <button href="javascript;" onClick={submitHandler} className="btn">
              Submit Case
            </button>
            {/* <button href="javascript;" onClick={()=>{batchChange()}} className="btn">
              Update Data..
            </button> */}
          </div>
        </div>
        <div
          className="float-child query"
          style={{
            // width: "50%",
            // backgroundColor: "aqua",
            // height: "83vh",
            // maxHeight: "65vh",
            maxHeight: "420px",
            overflow: "auto",
          }}
        >
          {/* Current Entries */}
          {data.map((obj, i) => (
            <React.Fragment key={i}>
              <p
                className="queryObj"
                style={{
                  margin: "5px",
                  padding: "7px",
                }}
              >
                Case Number:{" "}
                <span className="query_value">
                  {" "}
                  {obj.CASE_NUMBER ? obj.CASE_NUMBER : null}{" "}
                </span>
                <br />
                -- Incident Date:{" "}
                <span className="query_value">
                  {obj.INCIDENT_DATE.toDateString()}
                </span>
                <br />
                -- Associated Officers:{" "}
                <>
                  {obj.ASSOCIATED_OFFICERS_LIST.map((item) => (
                    <div className="tag-item" key={item}>
                      <span style={{ marginRight: "4px" }}>
                        {item.officer} ,
                      </span>
                      <span style={{ marginRight: "4px" }}>
                        <u>{item.badge}</u>
                      </span>
                      <span style={{ marginRight: "8px" }}>
                        <u>{item.badge2 ? item.badge2 : null}</u>
                      </span>
                    </div>
                  ))}
                </>
                <br />
                -- Incident Types:{" "}
                {/* <span className="query_value">{obj.INCIDENT_TYPE}</span> */}
                <span className="query_value">
                  {JSON.stringify(obj.INCIDENT_TYPES)}
                </span>
                <br />
                -- Date Created:{" "}
                <span className="query_value">
                  {obj.RECORD_CREATION_DATE.toDate().toDateString()}
                </span>
                <br />
                {obj.DATE_MODIFIED && (
                  <>
                    -- Last Updated:{" "}
                    <span>{obj.DATE_MODIFIED?.toDate().toString()}</span>
                  </>
                )}
              </p>
              <div style={{ width: "100%", textAlign: "right" }}>
                <button
                  className="edit"
                  onClick={() => {
                    navigate(`/edit/${obj.id}`);
                  }}
                >
                  edit
                </button>
                <button
                  className="delete"
                  onClick={() => {
                    deleteHandler(obj.id, obj.CASE_NUMBER);
                  }}
                >
                  delete
                </button>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="App">
        <section className="container">
          <div
            style={{
              // width: "100vw",
              minHeight: "220px",
              backgroundColor: "#dadada",
              padding: "14px 14px",
            }}
            {...getRootProps({
              className: "dropzone",
              // onClick: (event) => event.stopPropagation(),
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here...</p>
            ) : (
              // <p style={{padding:'6px 10px'}}>Drag 'n' drop some files here
              //  </p>
              // {/* , or click to select files */}
              // {myFiles.length() < 0 ? () : null}
              <div
                style={{
                  textAlign: "center",
                  // paddingTop: "10px",
                  cursor: "pointer",
                }}
              >
                {myFiles.length < 1 ? (
                  <div>
                    <div style={{ marginBottom: "-6px", marginTop: "30px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        fill="currentColor"
                        class="bi bi-file-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </div>
                  </div>
                ) : null}
                <h3>Click or drag file to this area to upload</h3>
                <h4>Supports single or bulk upload.</h4>
              </div>
            )}
            {myFiles.map(function (object, i) {
              return (
                <FilesHTML
                  key={i}
                  index={i}
                  object={object}
                  myFiles={myFiles}
                  progress={progress}
                  removeFile={removeFile}
                  currentlyUploading={currentlyUploadingObj}
                  fileMetadata={fileMetadata}
                  setFileMetadata={setFileMetadata}
                />
              );
            })}
          </div>

          {uploadStatus !== "--All Files Uploaded--" && myFiles.length > 0 && (
            <>
              {/* <button onClick={uploadFile}>Upload</button> */}
              <button onClick={removeAll}>Remove All</button>
            </>
          )}

          {uploadStatus}
        </section>
        <br />

        {/* {myFiles.map(function (object, i) {
          return (
            <FilesHTML
              key={i}
              index={i}
              object={object}
              myFiles={myFiles}
              progress={progress}
              removeFile={removeFile}
              currentlyUploading={currentlyUploadingObj}
              fileMetadata={fileMetadata}
              setFileMetadata={setFileMetadata}
            />
          );
        })} */}
      </div>
      {/* {fileMetadata?.map((obj, i) => (
        <div key={i}>{obj.path}</div>
      ))}
      {fileMetadata?.map((obj, i) => (
        <div key={i}>{obj.type}</div>
      ))} */}
      {/* {alert(()=>{fileMetadata[0]?.path})} */}
      {/* <button onClick={()=>{
        // alert(
        //   fileMetadata.map((file)=>{
        //     file.type
        //   })
        //   )
        alert(fileMetadata.map(i => '*' + i.type).join('\n'));


        //   // `${fileMetadata[0]?.type}\n${fileMetadata[1]?.type}\n${fileMetadata[2]?.type}`
        }}>show first item metadata</button>
      <br /> */}
      {/* <br /> */}
    </div>
  );
}

export default App;

const FilesHTML = (props) => {
  let index = props.index;
  let object = props.object;
  let progressParent = props.progress;
  let removeFile = props.removeFile;
  let currentlyUploading = props.currentlyUploading;
  let fileMetadata = props.fileMetadata;
  let setFileMetadata = props.setFileMetadata;

  const [progress, setProgress] = useState(0);
  // const [selected, setSelected] = useState(defaultFiletype);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (currentlyUploading.name == object.name) {
      setProgress(progressParent);
    }
  }, [progressParent]);

  useEffect(() => {
    if (progress < 10 && index !== undefined) {
      // console.log(index);
      // console.log(fileMetadata)
      let metadata = [...fileMetadata];
      // let metadata = [];
      let singleFile = fileMetadata.filter(
        (e) => e.path == object.path && e.size == object.size
      );

      let singleFileObj = {
        path: singleFile.path,
        size: singleFile.size,
        type: selected,
        // type: singleFile.type
      };

      singleFileObj["type"] = selected;
      // singleFileObj["type"] = 'testUpdated';
      singleFileObj["path"] = object.path;
      singleFileObj["size"] = object.size;
      metadata[index] = singleFileObj;
      // metadata[0] = singleFileObj;

      // console.log(metadata)
      // setFileMetadata(prevState=>{return [...prevState, ...metadata]})
      setFileMetadata(metadata);
    }
  }, [selected, index]);

  // useEffect(() => {
  //   if (fileMetadata.length > 0){
  //     console.log('fileMetadata', fileMetadata)
  //   }
  //   // setSelected(fileMetadata[index]?.type)
  //   // console.log(index)
  //   // setSelected(fileMetadata[index]?.type)

  // }, [fileMetadata.length, selected])

  useEffect(() => {
    // fileMetadata.length, selected
    setSelected(fileMetadata[index]?.type);
    // return () => {
    //   second
    // }
  }, [fileMetadata.length]);

  return (
    <div style={{ paddingBottom: "10px" }}>
      <li>{object.name}</li>
      {progress < 2 && (
        <>
          <label style={{ marginLeft: "20px" }} htmlFor="fruits">
            File Type:
          </label>{" "}
          <select
            id="fruits"
            name="fruits"
            defaultValue={selected}
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            <option>IPA Report</option>
            <option>Photo</option>
            <option>Audio</option>
          </select>
        </>
      )}
      {progress < 2 && (
        <button onClick={removeFile(object, index)}>Remove</button>
      )}
      {/* {fileMetadata[indexOfSelf]?.type} */}
      {/* index: {index} */}
      {progress > 2 && (
        <>
          <progress
            id="progressBar"
            value={progress}
            max="100"
            style={{ width: "300px" }}
          ></progress>
          {progress < 99 && <span>{progress}%</span>}
        </>
      )}
      {progress > 99 ? <span>Success!</span> : null}
    </div>
  );
};
