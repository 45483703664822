import React, { useState, useEffect, useContext, useCallback, } from "react";
// import { useParams } from "react-router-dom";
import PillGroup from "../components/PillGroup";

import { db, storage } from "../firebase";

import {
  collection,
  addDoc,
  updateDoc,
  // getDocs,
  // orderBy,
  // query,
  doc,
  // deleteDoc,
  Timestamp,
} from "firebase/firestore";

import {
  ref,
  // uploadBytes,
  uploadBytesResumable,
  // getDownloadURL,
  deleteObject,
  listAll,
} from "firebase/storage";

// import { useParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import BooksContext from "../context/BooksContext";

import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";

import options_IncidentType from "../incident-types"

import { useDropzone } from "react-dropzone";



// const options_IncidentType = [
//   { value: "Dishonesty", label: "Dishonesty" },
//   {
//     value: "Great Bodily Injury and Officer Involved Shooting",
//     label: "Great Bodily Injury and Officer Involved Shooting",
//   },
//   { value: "Sexual Assault", label: "Sexual Assault" },
// ];

const Agricultor = (props) => {
  let params = useParams();
  const navigate = useNavigate();

  // let params = useParams();

  const { books, setBooks } = useContext(BooksContext);
  const filteredBook = books.filter((book) => book.id === params.id)[0];

  const [DATE_CREATED, setDATE_CREATED] = useState();
  const [INCIDENT_DATE, setINCIDENT_DATE] = useState("");
  const [ASSOCIATED_OFFICERS_LIST, setASSOCIATED_OFFICERS_LIST] = useState([]);

  const [CASE_NUMBER, setCASE_NUMBER] = useState("");

  // const [INCIDENT_TYPE__text, setINCIDENT_TYPE__text] = useState("");
  // const [INCIDENT_TYPE__OBJ, setINCIDENT_TYPE__OBJ] = useState();

  const [INCIDENT_TYPES__text, setINCIDENT_TYPES__text] = useState([]);
  const [INCIDENT_TYPES__OBJ, setINCIDENT_TYPES__OBJ] = useState();

  const [filesMetadata, setFilesMetadata] = useState([]);

  const getFiles = () => {
    const listRef = ref(storage, `cases/${filteredBook.CASE_NUMBER}/`);

    listAll(listRef)
      .then((res) => {
        // res.items.forEach((itemRef) => {
        //   console.log('itemRef', itemRef)
        //   // deleteFile(itemRef.fullPath, itemRef.name)
        //   // deleteObject(itemRef).then(() => {
        //   //   // File deleted successfully
        //   // }).catch((error) => {
        //   //   console.log(error)
        //   //   // Uh-oh, an error occurred!
        //   // });
        // });
        res.prefixes.forEach((folderRef) => {
          // console.log('folderRef', folderRef)
          listAll(folderRef).then((res) => {
            res.items.forEach((itemRef) => {
              // console.log('itemRef', itemRef)
              // console.log('file location:', itemRef._location.path)
              setFilesMetadata((prevState) => [
                ...prevState,
                itemRef._location.path,
              ]);
            });
          });
          // getFiles(folderRef.fullPath)
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });
  };

  const deleteFile = (fileLocation, index) => {
    // Delete file from firebase storage

    const fileRef = ref(storage, fileLocation);
    deleteObject(fileRef)
      .then(() => {
        alert("Succesfully deleted from storage");
        // File deleted successfully
      })
      .catch((error) => {
        console.log(error);
        // Uh-oh, an error occurred!
      });

    // Now remove from local array
    let copy = [...filesMetadata];
    copy.splice(index);
    setFilesMetadata(copy);
  };

  useEffect(() => {
    console.log(filesMetadata);

    // return () => {
    //   second
    // }
  }, [filesMetadata]);

  useEffect(() => {
    getFiles();
    // console.log()

    // return () => {
    //   second
    // }
  }, []);

  useEffect(() => {
    if (filteredBook["INCIDENT_DATE"]) {
      const INCIDENT_DATE_dateObj = new Date(filteredBook["INCIDENT_DATE"]);

      if (typeof filteredBook["INCIDENT_DATE"] == "object") {
        setINCIDENT_DATE(filteredBook.INCIDENT_DATE);
        setDate(filteredBook.INCIDENT_DATE);
      } else if (typeof filteredBook["INCIDENT_DATE"] == "string") {
        setINCIDENT_DATE(filteredBook.INCIDENT_DATE);
        setDate(INCIDENT_DATE_dateObj);
      }
      setCASE_NUMBER(filteredBook.CASE_NUMBER);
      // setASSOCIATED_OFFICERS(filteredBook.ASSOCIATED_OFFICERS);
      setASSOCIATED_OFFICERS_LIST(filteredBook.ASSOCIATED_OFFICERS_LIST);

      
      // let tempIncidentsObj = {value: "", label: ""}
      let tempSelectionsArray = []

      filteredBook.INCIDENT_TYPES.forEach((element)=> {   // array with shape ["Dishonesty", "OIS", etc]
        let temp = {value: element, label: element}
        tempSelectionsArray.push(temp)
      })
      setINCIDENT_TYPES__OBJ(tempSelectionsArray)
      // alert(JSON.stringify(tempSelectionsArray))

      setINCIDENT_TYPES__text(filteredBook.INCIDENT_TYPES); // with shape ["Dishonesty", "OIS", etc]

      setDATE_CREATED(filteredBook.DATE_CREATED);
    }
    // effect
    // return () => {
    //   cleanup
    // }
  }, [filteredBook]);

  useEffect(() => {
    if (INCIDENT_TYPES__OBJ !== undefined) {
      let copy = [...INCIDENT_TYPES__OBJ]; 
      let valuesHolder = [];
      copy.forEach((element) => {
        // alert(JSON.stringify(element))
        valuesHolder.push(element.value);
      });
      // alert(JSON.stringify(valuesHolder));
      setINCIDENT_TYPES__text(valuesHolder);
    }
  }, [INCIDENT_TYPES__OBJ]); // this is now array.. []

  const submitHandler = async (event) => {
    event.preventDefault();

    await uploadFiles()

    const formData = {
      INCIDENT_DATE: INCIDENT_DATE,
      ASSOCIATED_OFFICERS_LIST: ASSOCIATED_OFFICERS_LIST,
      // INCIDENT_TYPE: INCIDENT_TYPE__OBJ.value,
      INCIDENT_TYPES: INCIDENT_TYPES__text,
      DATE_MODIFIED: Timestamp.now(),
    };

    console.log(formData);
    await updateDoc(doc(db, "cases", props.id), formData);
    navigate(`/cases`);
  };

  const [date, setDate] = useState();
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateChange = (d) => {
    // console.log(d);
    setINCIDENT_DATE(d);
    setDate(d);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: "30px",
      boxShadow: state.isFocused ? null : null,
      border: "0.04rem solid",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  // useEffect(() => {
  //   console.log(INCIDENT_TYPE__OBJ)
  // }, [INCIDENT_TYPE__OBJ])

  // useEffect(() => {
  //   alert(JSON.stringify(INCIDENT_TYPES__text));
  // }, [INCIDENT_TYPES__text]);

  let defaultFiletype = "IPA Report";



  const [myFiles, setMyFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState();



  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);

      setSelectedImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      // setSelectedImages(
      //   myFiles.map((file) =>
      //     Object.assign(file, {
      //       preview: URL.createObjectURL(file),
      //     })
      //   )
      // );

      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, inputRef } =
  useDropzone({ onDrop });


  function detectFiletype(filename) {
    var fileExt = filename.split(".").pop();
    switch (fileExt) {
      case "pdf":
        return "IPA Report";
      case "mp3":
      case "wav":
        return "Audio";
      case "jpg":
      case "jpeg":
      case "png":
        return "Photo";
      default:
        return filename === undefined ? defaultFiletype : filename;
    }
  }

  const removeFile = (file, index) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);

    console.log("removeFile...");
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    console.log(acceptedFiles);
    // console.log(myFiles);

    let fileMetadataCopy = [...fileMetadata];
    fileMetadataCopy.splice(index, 1);
    // console.log(fileMetadataCopy)

    setFileMetadata(fileMetadataCopy);
  };

  const removeAll = () => {
    console.log("removeAll...");
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    inputRef.current.value = "";
    console.log(acceptedFiles);

    setMyFiles([]);
  };

  const uploadFiles = async () => {
    await Promise.all(
      myFiles.map((file, i, myFilesObj) => {
        console.log(file);

        const storageRef = ref(
          storage,
          `cases/${CASE_NUMBER}/${fileMetadata[i].type}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);

        setUploadStatus("Uploading");

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progressSnap =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            setCurrentlyUploadingObj(snapshot.task._blob.data_);

            // console.log("Upload is " + progressSnap + "% done");
            setProgress(progressSnap);

            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            if (i + 1 === myFilesObj.length) {
              // Last one.
              // setMyFiles([]);
              setUploadStatus("--All Files Uploaded--");
              // sendDocData();
              // setProgress(0)
              // console.log("last one finished !!!");
            } else {
              // Not last one.
            }
          }
        );
      })
    );
  };


  const [currentlyUploadingObj, setCurrentlyUploadingObj] = useState({});
  const [uploadStatus, setUploadStatus] = useState(null);
  const [progress, setProgress] = useState(0);

  const [fileMetadata, setFileMetadata] = useState();

  useEffect(() => {
    // Here we want to sync fileMetadata with myFiles

    let fileMetadataTemp = [];
    // console.log(myFiles)
    if (myFiles.length !== fileMetadata?.length) {
      myFiles.map((file, i) => {
        let fileType = fileMetadata[i]?.type;
        // let fileType = 'init'
        let fileDataObj = {
          path: file.path,
          size: file.size,
          // type: defaultFiletype,
          // type: fileType === undefined ? defaultFiletype : fileType,
          type: detectFiletype(file.path),
        };
        fileMetadataTemp.push(fileDataObj);
      });
      setFileMetadata(fileMetadataTemp);
    }
  }, [myFiles, fileMetadata]);


  return (
    <div style={{ padding: "10px", backgroundColor: "silver" }}>
      <div style={{ textAlign: "center" }}>Case Number: {CASE_NUMBER}</div>

      {/* <div style={{ textAlign: "center" }}>Entry ID: {props.id}</div> */}
      {/* <div style={{ textAlign: "center" }}>Date Created: {filteredBook?.fecha_creacion.toDate()?.toDateString()}</div> */}
      {/* <div>{props}</div> */}
      <div className="label">Incident Date:</div>
      <DatePicker
        // placeholderText="DD/MM/YYYY"
        placeholderText="MM/DD/YYYY"
        // dateFormat="dd/MM/yyyy"
        dateFormat="MM/dd/yyyy"
        selected={date}
        onChange={(date) => handleDateChange(date)}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
      />
      <div className="label">Incident Type:</div>
      <Select
        // value={INCIDENT_TYPE}
        value={INCIDENT_TYPES__OBJ}
        options={options_IncidentType}
        // onChange={setINCIDENT_TYPE}
        onChange={setINCIDENT_TYPES__OBJ}
        // isSearchable={true}
        isMulti={true}
        // styles={customStyles}
        // defaultValue={options[0]}
        isClearable={true}
        // getOptionLabel={(option) => option["name"]}
      />
      <div className="label">Associated Officers:</div>
      <PillGroup
        ASSOCIATED_OFFICERS_LIST={ASSOCIATED_OFFICERS_LIST}
        setASSOCIATED_OFFICERS_LIST={setASSOCIATED_OFFICERS_LIST}
      />

      <br />
      <button href="javascript;" onClick={submitHandler} className="btn">
        Submit
      </button>

      <br />
      <br />

      <div style={{ borderTop: "solid grey 1px" }}>
        <div style={{ textAlign: "center" }}>Associated Files:</div>
        <br />
        {/* {filesMetadata.map((obj)=>{
          <div>obj</div>
        })} */}
        {filesMetadata.length < 1 ? (
          <div style={{ textAlign: "center" }}>N/A</div>
        ) : (
          <>
            {filesMetadata?.map((obj, i) => (
              <div key={i}>
                <button
                  style={{ marginRight: "8px", marginBottom: "4px" }}
                  onClick={() => {
                    deleteFile(obj, i);
                  }}
                >
                  Delete
                </button>
                <span>{obj.slice(6)}</span>
              </div>
            ))}
          </>
        )}
        {/* {filesMetadata?.map((obj, i) => (
          <div key={i}>
            <button style={{marginRight:'8px', marginBottom:'4px'}}
              onClick={() => {
                deleteFile(obj, i);
              }}
            >
              Delete
            </button>
            <span>{obj.slice(6)}</span>
          </div>
        ))} */}
      </div>
      <br />

      {/* <div style={{ backgroundColor: "grey", minHeight: "200px" }}>
        Drag 'n' drop some files here
      </div> */}

      <section className="container">
          <div
            style={{
              // width: "100vw",
              minHeight: "220px",
              backgroundColor: "#dadada",
              padding: "14px 14px",
            }}
            {...getRootProps({
              className: "dropzone",
              // onClick: (event) => event.stopPropagation(),
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here...</p>
            ) : (
              // <p style={{padding:'6px 10px'}}>Drag 'n' drop some files here
              //  </p>
              // {/* , or click to select files */}
              // {myFiles.length() < 0 ? () : null}
              <div
                style={{
                  textAlign: "center",
                  // paddingTop: "10px",
                  cursor: "pointer",
                }}
              >
                {myFiles.length < 1 ? (
                  <div>
                    <div style={{ marginBottom: "-6px", marginTop: "30px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        fill="currentColor"
                        class="bi bi-file-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </div>
                  </div>
                ) : null}
                <h3>Click or drag file to this area to upload</h3>
                <h4>Supports single or bulk upload.</h4>
              </div>
            )}
            {myFiles.map(function (object, i) {
              return (
                <FilesHTML
                  key={i}
                  index={i}
                  object={object}
                  myFiles={myFiles}
                  progress={progress}
                  removeFile={removeFile}
                  currentlyUploading={currentlyUploadingObj}
                  fileMetadata={fileMetadata}
                  setFileMetadata={setFileMetadata}
                />
              );
            })}
          </div>

          {uploadStatus !== "--All Files Uploaded--" && myFiles.length > 0 && (
            <>
              <button onClick={uploadFiles}>Upload</button>
              <button onClick={removeAll}>Remove All</button>
            </>
          )}

          {uploadStatus}
        </section>


    </div>
  );
};

export default Agricultor;


const FilesHTML = (props) => {
  let index = props.index;
  let object = props.object;
  let progressParent = props.progress;
  let removeFile = props.removeFile;
  let currentlyUploading = props.currentlyUploading;
  let fileMetadata = props.fileMetadata;
  let setFileMetadata = props.setFileMetadata;

  const [progress, setProgress] = useState(0);
  // const [selected, setSelected] = useState(defaultFiletype);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (currentlyUploading.name == object.name) {
      setProgress(progressParent);
    }
  }, [progressParent]);

  useEffect(() => {
    if (progress < 10 && index !== undefined) {
      // console.log(index);
      // console.log(fileMetadata)
      let metadata = [...fileMetadata];
      // let metadata = [];
      let singleFile = fileMetadata.filter(
        (e) => e.path == object.path && e.size == object.size
      );

      let singleFileObj = {
        path: singleFile.path,
        size: singleFile.size,
        type: selected,
        // type: singleFile.type
      };

      singleFileObj["type"] = selected;
      // singleFileObj["type"] = 'testUpdated';
      singleFileObj["path"] = object.path;
      singleFileObj["size"] = object.size;
      metadata[index] = singleFileObj;
      // metadata[0] = singleFileObj;

      // console.log(metadata)
      // setFileMetadata(prevState=>{return [...prevState, ...metadata]})
      setFileMetadata(metadata);
    }
  }, [selected, index]);

  // useEffect(() => {
  //   if (fileMetadata.length > 0){
  //     console.log('fileMetadata', fileMetadata)
  //   }
  //   // setSelected(fileMetadata[index]?.type)
  //   // console.log(index)
  //   // setSelected(fileMetadata[index]?.type)

  // }, [fileMetadata.length, selected])

  useEffect(() => {
    // fileMetadata.length, selected
    setSelected(fileMetadata[index]?.type);
    // return () => {
    //   second
    // }
  }, [fileMetadata.length]);

  return (
    <div style={{ paddingBottom: "10px" }}>
      <li>{object.name}</li>
      {progress < 2 && (
        <>
          <label style={{ marginLeft: "20px" }} htmlFor="fruits">
            File Type:
          </label>{" "}
          <select
            id="fruits"
            name="fruits"
            defaultValue={selected}
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            <option>IPA Report</option>
            <option>Photo</option>
            <option>Audio</option>
          </select>
        </>
      )}
      {progress < 2 && (
        <button onClick={removeFile(object, index)}>Remove</button>
      )}
      {/* {fileMetadata[indexOfSelf]?.type} */}
      {/* index: {index} */}
      {progress > 2 && (
        <>
          <progress
            id="progressBar"
            value={progress}
            max="100"
            style={{ width: "300px" }}
          ></progress>
          {progress < 99 && <span>{progress}%</span>}
        </>
      )}
      {progress > 99 ? <span>Success!</span> : null}
    </div>
  );
};