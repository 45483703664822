import React from "react";
import ReactDOM from "react-dom";

import "./../styles/pill-styles.css";

class PillGroup extends React.Component {
  state = {
    // items: [],
    // value: ["" , ""],
    value: {officer: "", badge: "", badge2: ""},
    error: null
  };

  handleKeyDown = evt => {
    // if (["Enter", "Tab", ","].includes(evt.key)) {
    if (["Enter"].includes(evt.key)) {
      // if (evt.preventDefault) {
      //   evt.preventDefault();
      // }

      var value = this.state.value.officer.trim();
      var value2 = this.state.value.badge.trim();

      if (value && value2 && this.isValid(value)) {
        // this.setState({
        //   // items: [...this.state.items, this.state.value],
        //   // value: ["", ""]
        //   // value: {officer: "", badge: ""},
        // });
        this.props.setASSOCIATED_OFFICERS_LIST([...this.props.ASSOCIATED_OFFICERS_LIST, this.state.value])
        this.setState({ value: { officer: "", badge: "", badge2: "" } });
      }
    }
  };

  handleCreateOfficer = (evt) => {
    var value = this.state.value.officer.trim();
    var value2 = this.state.value.badge.trim();

    if (value && value2 && this.isValid(value)) {
      this.props.setASSOCIATED_OFFICERS_LIST([...this.props.ASSOCIATED_OFFICERS_LIST, this.state.value])
      this.setState({
        // items: [...this.state.items, this.state.value],
        // value: ["", ""]
        value: {officer: "", badge: "", badge2: ""},
      });
    }
  }

  // handleChange = evt => {
  //   this.setState({
  //     value: evt.target.value,
  //     error: null
  //   });
  // };

  handleChangeOfficer = evt => {
    // 1. Make a shallow copy of the items
    // let values = [...this.state.value];
    let values = {...this.state.value};
    // 2. Make a shallow copy of the item you want to mutate
    // let value0 = {...values[0]}
    let valueOfficer = values.officer
    // 3. Replace the property you're intested in
    valueOfficer = evt.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    values.officer = valueOfficer;
    // 5. Set the state to our new copy
    this.setState({
      value: values,
      error: null
    });
    // console.log(this.state.value)
  };

  handleChangeBadge = evt => {
    // 1. Make a shallow copy of the items
    // let values = [...this.state.value];
    let values = {...this.state.value};
    // 2. Make a shallow copy of the item you want to mutate
    // let value0 = {...values[0]}
    let valueBadge = values.badge
    // 3. Replace the property you're intested in
    valueBadge = evt.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    values.badge = valueBadge;
    // 5. Set the state to our new copy
    this.setState({
      value: values,
      error: null
    });
    // console.log(this.state.value)
  };

  handleChangeBadge2 = evt => {
    // 1. Make a shallow copy of the items
    // let values = [...this.state.value];
    let values = {...this.state.value};
    // 2. Make a shallow copy of the item you want to mutate
    // let value0 = {...values[0]}
    let valueBadge2 = values.badge2
    // 3. Replace the property you're intested in
    valueBadge2 = evt.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    values.badge2 = valueBadge2;
    // 5. Set the state to our new copy
    this.setState({
      value: values,
      error: null
    });
    // console.log(this.state.value)
  };



  handleDelete = item => {
    // this.setState({
    //   items: this.state.items.filter(i => i !== item)
    // });

    this.props.setASSOCIATED_OFFICERS_LIST(
      this.props.ASSOCIATED_OFFICERS_LIST.filter(i => i !== item)
    )
    
  };

  handlePaste = evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    // var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    var emails = paste.match(/[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));

      // this.setState({
      //   items: [...this.state.items, ...toBeAdded]
      // });
      
      this.props.setOfficersList([...this.props.ASSOCIATED_OFFICERS_LIST, ...toBeAdded])
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    // if (!this.isEmail(email)) {                            // Not needed as we dont need to valiate if entry is email
    //   error = `${email} is not a valid email address.`;
    // }

    if (error) {
      this.setState({ error });                               // Errors stored in child class component, officers state array stored in parent component and pass as prop
      return false;
    }
    return true;
  }

  isInList(email) {
    return this.props.ASSOCIATED_OFFICERS_LIST.includes(email);
    // return this.state.items.includes(email);
  }

//   isEmail(email) {
//     return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
//   }

  render() {
    return (
      <>

        <div class="tooltip">
          <input
            className={"input " + (this.state.error && " has-error")}
            value={this.state.value.officer}
            // value={this.state.value[0]}
            placeholder="Officer Name"
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChangeOfficer}
            //   onPaste={this.handlePaste}
            style={{ marginBottom: "8px", width: "70%" }}
          />

          {/* <input type="text" /> */}
          <input
            className={"text input " + (this.state.error && " has-error")}
            value={this.state.value.badge}
            // value={this.state.value[1]}
            // maxlength="4"
            placeholder="Badge Number ######"
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChangeBadge}
            //   onPaste={this.handlePaste}
            style={{ marginBottom: "8px", width: "70%" }}
          />

          <input
            className={"text input " + (this.state.error && " has-error")}
            value={this.state.value.badge2}
            // value={this.state.value[1]}
            // maxlength="4"
            placeholder="Badge Number 2nd ######"
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChangeBadge2}
            //   onPaste={this.handlePaste}
            style={{ marginBottom: "8px", width: "70%" }}
          />
          <br/>
          <span class="tooltiptext"> Make sure to click "Add officer" before submitting case information.</span>
        </div>

        <br />

        <button
          onClick={() => {
            this.handleCreateOfficer();
            this.setState({ value: { officer: "", badge: "", badge2: "" } });
          }}
        >
          Add officer
        </button>

        <br />

        <div style={{ paddingTop: "2px" }}>
          {this.props.ASSOCIATED_OFFICERS_LIST.map((item) => (
            <div className="tag-item" key={item}>
              <span style={{ marginRight: "4px" }}>{item.officer} ,</span>

              <span style={{ marginRight: "4px" }}>
                <u>{item.badge}</u> ,
              </span>
              <u>{item.badge2 ? item.badge2 : null}</u>
              <button
                type="button"
                className="button"
                onClick={() => this.handleDelete(item)}
              >
                &times;
              </button>
            </div>
          ))}
          <div style={{ minHeight: "12px" }}>
            {this.state.error && <p className="error">{this.state.error}</p>}
          </div>
        </div>
        {/* {this.state.items.map(item => ( */}
      </>
    );
  }
}

export default PillGroup;
