const options_IncidentType = [
  {
    value: "Great Bodily Injury",
    label: "Great Bodily Injury",
  },
  {
    value: "Officer Involved Shooting",
    label: "Officer Involved Shooting",
  },
  { value: "Excessive Force", label: "Excessive Force" },
  {
    value: "Officer Failing to Intervene in Excessive Force Incident",
    label: "Officer Failing to Intervene in Excessive Force Incident",
  },
  { value: "Unlawful Search", label: "Unlawful Search" },
  { value: "Dishonesty", label: "Dishonesty" },
  { value: "Sexual Assault", label: "Sexual Assault" },
  {
    value: "Prejudice or Discrimination",
    label: "Prejudice or Discrimination",
  },
];

export default options_IncidentType;
