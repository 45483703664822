/* global grecaptcha */
import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Link,
  Outlet,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import BooksContext from "../context/BooksContext";
// import AuthContext from "../context/AuthContext";

import CaseUploadScreen from "../screens/CaseUploadScreen";
import Fincas from "../screens/Fincas";

import EditCase from "../screens/Edit/EditCase";
import { fakeAuthProvider } from "./auth";

import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import { RecaptchaVerifier } from "firebase/auth";

let AuthContext = React.createContext();

const auth = getAuth();

function AuthProvider({ children }) {
  let navigate = useNavigate();

  let [user, setUser] = React.useState();
  // let [user, setUser] = React.useState("admin");
  // const [user, setUser] = useLocalStorage("user", "");
  // const [user, setUser] = useLocalStorage("user", "admin");
  const captchaRef = React.useRef(null);

  const selectedIndex = 0;

  const [showMFA, setShowMFA] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  // const [codeSubmitted, setCodeSubmitted] = useState(false);

  
  const [verificationId_STATE, setVerificationId_STATE] = useState(null)
  const [resolver_STATE, setResolver_STATE] = useState(null)

  function completeMFA() {
    const resolver = resolver_STATE
    const cred = PhoneAuthProvider.credential(verificationId_STATE, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    return resolver
      .resolveSignIn(multiFactorAssertion)
      .then(function (userCredential) {
        // User successfully signed in with the second factor phone number.
        navigate("/cases");
        setShowMFA(false);
        setVerificationCode(null)
      });
  }
  
  let signin = (email, password, callback) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        alert("signed in");
        callback();
        // setUser(user)
        // console.log(user)
        // ...
      })
      .catch((error) => {
        if (error.code == "auth/multi-factor-auth-required") {
          // The user is a multi-factor user. Second factor challenge is required.
          const resolver = getMultiFactorResolver(auth, error);
          setResolver_STATE(resolver)

          const recaptchaVerifier = new RecaptchaVerifier(
            captchaRef.current,
            {
              size: "invisible",
              callback: function (response) {
                // reCAPTCHA solved, you can proceed with
                // phoneAuthProvider.verifyPhoneNumber(...).
                // onSolvedRecaptcha();
                console.log("worked");
              },
            },
            auth
          );

          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[selectedIndex],
            session: resolver.session,
          };

          // Send SMS verification code.
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(async function (verificationId) {
              setVerificationId_STATE(verificationId)
              
              alert("code sent");
              // verificationId will be needed for sign-in completion.

              setShowMFA(true);
              // completeMFA(verificationId, resolver);
            });

          grecaptcha.reset(window.recaptchaWidgetId);
          
          // Or, if you haven't stored the widget ID:
          // recaptchaVerifier.render().then(function (widgetId) {
          //   grecaptcha.reset(widgetId);
          // });

          // ...
        } else if (error.code == "auth/wrong-password") {
          // Handle other errors such as wrong password.
        }

        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
      });

    // return fakeAuthProvider.signin(() => {
    //   setUser(newUser);
    //   callback();
    // });
  };

  let signout = (callback) => {
    return fakeAuthProvider.signout(() => {
      signOut(auth)
        .then(() => {
          setUser(null);
          callback();
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
    });
  };

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     setUser(user);
  //   });
  //   return unsubscribe;
  // }, []);

  useEffect(() => {
    // setPending(true)
    auth.onAuthStateChanged((user)=>{
      if (user) {
        setUser(user)
        navigate("/cases"); 
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
      } else {
        // User is signed out
        // ...
      }
    
      // setPending(false)
    })
  }, [])

  let value = {
    user,
    signin,
    signout,
    verificationCode,
    setVerificationCode,
    // setCodeSubmitted,
    showMFA,
    setShowMFA,
    completeMFA
  };

  return (
    <AuthContext.Provider value={value}>
      <div id="recaptcha-container" ref={captchaRef}></div>
      {children}
    </AuthContext.Provider>
  );
}

const AppRouter = () => {
  const [books, setBooks] = useLocalStorage("books", []);

  // if (!auth.user) {
  // return <Navigate to="/login" />;
  // }

  // if (auth.user) {
  //   return <Navigate to="/cases" />;
  // }

  return (
    <AuthProvider>
      <BooksContext.Provider value={{ books, setBooks }}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            {/* <Route exact path="/login">
              {auth.user ? <Navigate to="/cases" /> : <LoginPage />}
            </Route> */}
            <Route element={<RequireAuth />}>
              <Route path="/protected" element={<ProtectedPage />} />
              <Route path="/cases" element={<CaseUploadScreen />} />
              <Route path="/edit/:id" element={<EditCase />} />
              <Route path="/fincas" element={<Fincas />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BooksContext.Provider>
    </AuthProvider>
  );
};

function Layout() {
  return (
    <div>
      <ul>
        {/* <li>
          <Link to="/public">Public Pageee</Link>
        </li> */}
        {/* <li>
          <Link to="/protected">Protected Page</Link>
        </li> */}
        {/* <li>
          <Link to="/cases">Cases (Protected Page)</Link>
        </li> */}
        {/* <li>
          <Link to="/fincas">Fincas (Protected Page)</Link>
        </li> */}
      </ul>
      <AuthStatus />

      <Outlet />
    </div>
  );
}

function useAuth() {
  return React.useContext(AuthContext);
}

function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  React.useEffect(() => {
    if (auth.user) {
      console.log("hi");
      // window
      // history.push('/cases')
      navigate("cases");
    }
  }, []);

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <div style={{ marginLeft: "20px" }}>
      <p>
        Welcome <b>{auth.user?.email}</b>
      </p>
      <button
        onClick={() => {
            navigate("/cases"); 
            window.location.reload(false);
        }}
      >
        Cases
      </button>
      <button
        onClick={() => {
          auth.signout(
            () => {
            navigate("/"); 
            window.location.reload(false);
          }
            
            );
        }}
      >
        Sign out
      </button>
    </div>
  );
}

function RequireAuth() {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  useEffect(() => {
    if (auth.user !== null) {
      // console.log(auth);
    }
    // return () => {
    //   cleanup
    // }
  }, [auth]);

  let state = location.state;
  let from = state ? state.from.pathname : "/";

  function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username");
    let password = formData.get("password");

    auth.signin(username, password, () => {
      // Callback here
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      // navigate(from, { replace: true });
      navigate("/cases");
    });
    // auth.signin(username, password)
  }

  return (
    <div>
      {auth.showMFA ? (
        <div style={{ textAlign: "center" }}>
          Code Required <br />
          <input
            placeholder="Verification Code"
            value={auth.verificationCode}
            onChange={(e) => auth.setVerificationCode(e.target.value)}
          />
          <button
            onClick={() => {
              // auth.setCodeSubmitted(true);
              auth.completeMFA(auth.verificationCode)
              .catch((error) => {
                if (error.code == "auth/invalid-verification-code") {alert('wrong code!')}
                else if (error.code == "auth/too-many-requests"){alert('too many requests, please wait some time before trying again.')}
            })}}
              style={{ fontSize: "12px", padding: "6px 22px" }}
          >
            Submit MFA
          </button>
        </div>
      ) : (
        <>
          <p>You must log in to view the page at {from}</p>

          <form
            onSubmit={handleSubmit}
            style={{
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              border: "1px solid",
              padding: "10px",
            }}
          >
            <label>
              Username:{" "}
              <input
                style={{ marginBottom: "10px" }}
                name="username"
                type="text"
                autocomplete="off"
                placeholder="Username"
              />
            </label>
            <label>
              Password:{" "}
              <input
                style={{ marginBottom: "10px" }}
                name="password"
                type="password"
                autocomplete="off"
                placeholder="Password"
              />
            </label>

            <div style={{ textAlign: "center", marginTop: "8px" }}>
              <button
                type="submit"
                // onClick={()=>auth.setShowMFA(true)}
                style={{ fontSize: "12px", padding: "6px 22px" }}
              >
                SIGN IN
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

function PublicPage() {
  return <h3>Public</h3>;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}

function Dashboard() {
  return <h3>Dashboard</h3>;
}

function NotFound() {
  return <h3>NotFound</h3>;
}

export default AppRouter;
